"use client";

import { zodResolver } from "@hookform/resolvers/zod";
import { Divider, Link } from "@nextui-org/react";
import { useGoogleLogin } from "@react-oauth/google";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";
import Button from "@/components/ui/button";
import GoogleLoginButton from "@/components/ui/GoogleLoginButton";
import Input from "@/components/ui/input";
import Authlayout from "@/components/ui/layout/auth-layout";
import PerDiemLogo from "@/components/ui/perdiem-logo";
import { useGoogleSignin } from "@/hooks/mutations/auth/googleSigin";
import { useLoginUser } from "@/hooks/mutations/auth/use-auth-mutation";
import useAuth from "@/hooks/use-auth";
import { UserLoginSchema } from "@/schemas/auth/auth.schema";
export default function Login() {
  const googleSignin = useGoogleSignin();
  const [googleLoading, setGoogleLoading] = useState(false);
  const {
    LoginSucess
  } = useAuth();
  const loginUser = useLoginUser();
  type UserLoginType = z.infer<typeof UserLoginSchema>;
  const {
    register,
    handleSubmit
  } = useForm<UserLoginType>({
    resolver: zodResolver(UserLoginSchema)
  });
  const onSubmit: SubmitHandler<UserLoginType> = data => {
    loginUser.mutate({
      email: data.email,
      password: data.password
    }, {
      onSuccess: LoginSucess
    });
  };
  const googleLogin = useGoogleLogin({
    onSuccess: token => {
      googleSignin.mutate({
        token: token.code
      }, {
        onSuccess: LoginSucess,
        onError: () => {
          setGoogleLoading(false);
          toast.error("Error Signing up with google");
        }
      });
    },
    onNonOAuthError: () => {
      setGoogleLoading(false);
    },
    onError: error => {
      setGoogleLoading(false);
      if (error && error?.error === "access_denied") {
        toast.error("Google sign-in cancelled. Please try again.");
      } else {
        toast.error("Error Signing up with google");
      }
    },
    flow: "auth-code"
  });
  return <div className="flex min-h-screen flex-col bg-surface-default" data-sentry-component="Login" data-sentry-source-file="login.tsx">
      <Authlayout data-sentry-element="Authlayout" data-sentry-source-file="login.tsx">
        <div className="flex w-full flex-col gap-[10px] md:w-[460px] md:max-w-lg">
          <div className="flex w-full items-center justify-center">
            <PerDiemLogo width={70} height={43} data-sentry-element="PerDiemLogo" data-sentry-source-file="login.tsx" />
          </div>
          <h1 className="text-center text-[22px] font-semibold">Sign in</h1>
          <div className="flex items-center justify-center gap-1">
            <p className="text-sm">Don’t have a Per Diem user?</p>
            <Link href="/signup" className="cursor-pointer text-sm text-blue-default" data-sentry-element="Link" data-sentry-source-file="login.tsx">
              Create an account
            </Link>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-[10px]">
            <Input className="data-[focus=true]:text-primary" labelPlacement="inside" label="Email" placeholder="Enter email address" {...register("email", {
            required: true
          })} data-sentry-element="Input" data-sentry-source-file="login.tsx" />
            <Input type="password" labelPlacement="inside" label="Password" placeholder="At least 8 characters" isPassword {...register("password", {
            required: true
          })} data-sentry-element="Input" data-sentry-source-file="login.tsx" />

            <Link href="/forget-password" className="cursor-pointer text-sm text-blue-default" data-sentry-element="Link" data-sentry-source-file="login.tsx">
              Forgot password?
            </Link>
            <div className="flex w-full items-center">
              <Button type="submit" className="w-full" size="lg" isDisabled={loginUser.isPending} isLoading={loginUser.isPending} data-sentry-element="Button" data-sentry-source-file="login.tsx">
                Sign in
              </Button>
            </div>
            <div className="flex w-full items-center justify-center gap-5">
              <Divider className="w-20" data-sentry-element="Divider" data-sentry-source-file="login.tsx" />
              <p className="text-sm font-normal">or</p>
              <Divider className="w-20" data-sentry-element="Divider" data-sentry-source-file="login.tsx" />
            </div>
            <div className="flex w-full items-center">
              <GoogleLoginButton loading={googleLoading} onClick={() => {
              setGoogleLoading(true);
              googleLogin();
            }} buttonLabel="Sign in with Google" data-sentry-element="GoogleLoginButton" data-sentry-source-file="login.tsx" />
            </div>
          </form>
        </div>
      </Authlayout>
    </div>;
}